import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    getUserDetails,
} from "userData";
import { useHistory } from 'react-router';
// reactstrap components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
    Navbar,
    Nav,
    Container,
    Media,
} from "reactstrap";

const CustomerNavbar = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    useEffect(() => {

    }, []);
    const userLogout = async () => {
        localStorage.clear();
        window.localStorage.setItem('usertoken', 0);
        window.localStorage.setItem('user', 0);
        window.location.assign("/auth/login");
    };
    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid>
                    <Link
                        className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
                        to="/customer/dashboard"
                    >
                        {props.brandText}
                    </Link>

                    <Nav className="align-items-center d-none d-md-flex" navbar>
                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        <img
                                            alt="..."
                                            src={
                                                require("../../assets/img/theme/team-4-800x800.jpg")
                                                    .default
                                            }
                                        />
                                    </span>
                                    <Media className="ml-2 d-none d-lg-block">
                                        <span className="mb-0 text-sm font-weight-bold">
                                            {user.first_name + " " + user.last_name}
                                        </span>
                                    </Media>
                                </Media>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Welcome!</h6>
                                </DropdownItem>
                                <DropdownItem 
                                    onClick={
                                        () => {
                                            history.push(`/customer/profile`)
                                        }
                                }
                                >
                                    <i className="ni ni-single-02" />
                                    <span>My profile</span>
                                </DropdownItem>
                                <DropdownItem onClick={
                                    () => {
                                        history.push(`/customer/changepassword`)
                                    }
                                }>
                                    <i className="ni ni-settings-gear-65" />
                                    <span>Change password</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem href="#pablo" onClick={() => userLogout()}>
                                    <i className="ni ni-user-run" />
                                    <span>Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
};

export default CustomerNavbar;
