import React, { useState, useEffect } from "react";
import {
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    CardTitle,
    Label,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import SearchBar from 'material-ui-search-bar';
import {
    TablePagination,
    TableFooter,
    colors,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
} from '@material-ui/core';
import { BorderColor } from "@material-ui/icons";
import swal from 'sweetalert';
import DataPagination from "react-js-pagination";

const Devices = () => {
    const history = useHistory();
    const [user, setUser] = useState(getUserDetails());
    const [devicelist, setdevicelist] = useState([]);
    const [searched, setSearched] = useState('');
    const [devicelistFromServer, setdevicelistFromServer] = useState([]);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
        getData();
    }, []);
    const getAllDeviceList = async () => {
        const response = await axios.get(`devices`);
        return response;
    };
    const handleChangePage = (event, page) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = event => {
        setrowsPerPage(event.target.value);
    };
    const getData = async () => {
        await getAllDeviceList().then((response) => {
            if (response.data.status == 400) {
                console.log('error')
            } else {
                console.clear()
                console.log('no error')
                console.log(response.data.data)
                setdevicelist(response.data.data)
                setdevicelistFromServer(response.data.data)
            }
        });
    };
    const updateDeviceStatus = async (id, status) => {
        const response = await axios
            .post('devices/statusupdate',
                { div_id: id, status: status },
                {
                    headers: {
                        'Content-type': 'application/json',
                    },
                },
            );
        if (response.data.error) {

            return response;
        } else {
            if (status == "1") {
                swal("Device Enabled successfully!", { icon: "success" });
            }
            else {
                swal("Device Disabled successfully!", { icon: "success" });
            }
            getData();
        }
    };
    const cancelSearch = () => {
        setSearched('');
        setdevicelist(devicelistFromServer);
    };
    const isNumeric = value => {
        return /^-?\d+$/.test(value);
    };
    const requestSearch = searchedVal => {
        console.log('searchedVal : ' + searchedVal)
        if (searchedVal == '') {
            setdevicelist(devicelistFromServer);
        } else {
            const filteredRows = devicelist.filter(row => {
                return (
                    row.device_name.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.device_id.toLowerCase().includes(searchedVal.toLowerCase()) ||
                    row.device_location.toLowerCase().includes(searchedVal.toLowerCase())
                );
            });
            setdevicelist(filteredRows);
        }
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Device List</h3>
                                    </div>
                                    { /*
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4",
                                            }}
                                            color="primary"
                                            onClick={() => history.push(`/admin/adddevice`)}
                                            size="sm">
                                            Add new device
                                    </Button>
                                    </div>
                                    */}
                                </Row>
                            </CardHeader>

                            <Box component="div" mt={5} mb={5} ml={5} mr={5}>
                                <SearchBar
                                    style={{ height: 'auto' }}
                                    value={searched}
                                    onChange={searchVal => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                />
                            </Box>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">No</th>
                                        <th scope="col">Device Name</th>
                                        <th scope="col">Device id</th>
                                        <th scope="col">device location</th>
                                        <th scope="col">Created At</th>
                                        { /*<th scope="col" />*/}
                                    </tr>
                                </thead>
                                <tbody>
                                    {devicelist.length != 0 ?
                                        (devicelist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{page * rowsPerPage + index + 1}</th>
                                                    <td>{row.device_name}</td>
                                                    <td>{row.device_id}</td>
                                                    <td>{row.device_location}</td>
                                                    <td><Moment format="DD-MM-YYYY hh:mm:ss">{row.created_at}</Moment></td>
                                                    {/*
                                                     <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                className="btn-icon-only text-light"

                                                                role="button"
                                                                size="sm"
                                                                color=""
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <i className="fas fa-ellipsis-v" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                <DropdownItem
                                                                    onClick={() => history.push(`/admin/editdevice`, row)}>Edit</DropdownItem>
                                                                <DropdownItem
                                                                    onClick={() => {
                                                                        let status = row.status == '1' ? '0' : '1'
                                                                        updateDeviceStatus(row.id, status)
                                                                    }}>
                                                                    {row.status == "1" ? "Inactive" : "Active"}
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                    */}
                                                </tr>
                                            );
                                        }))
                                        :
                                        <tr><td colSpan="7" style={{ textAlign: "center", fontSize: 20 }}>No Record Found</td></tr>
                                    }
                                </tbody>
                                <TableFooter>
                                    <TablePagination
                                        className="customFontFamily"
                                        count={devicelist.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableFooter>
                            </Table>

                            
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Devices;
