export const getUserDetails = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("user")) {        
        return JSON.parse(localStorage.getItem("user"));        
    } else {
        return false;
    }
};
export const getUserToken = () => {
    if (typeof window == "undefined") {
        return false;
    }
    if (localStorage.getItem("user")) {
        return JSON.parse(localStorage.getItem("usertoken"));
    } else {
        return false;
    }
};