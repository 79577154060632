import React, { useState, useEffect } from "react";

import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button, MenuItem, Select,
} from '@material-ui/core';
import swal from 'sweetalert';

const Tables = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }

    const [UserId, setUserId] = useState(props.location.state.id);
    const [KeyListArray, setKeyListArray] = useState([]);    

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }        
        getAllKeysList();

    }, []);

    const getAllKeysList = async () => {
        const response = await axios.get(`users/getUsersWiseKeys?user_id=` + UserId);
        setKeyListArray(response.data.data);
    };

    const UpdateKeysList = async () => {
        //console.log(JSON.stringify(KeyListArray))
        await NewUpdateKeysList(KeyListArray).then((response) => {
            if (response.data.status == 400) {
                swal(response.data.message, { icon: "error" });
            } else {
                swal(response.data.message, { icon: "success" });
                //history.goBack();
            }
        });
    };

    const NewUpdateKeysList = async (KeyListArray) => {
        const response = await axios.post(`users/scaleupdate`, {
            payload_val: KeyListArray,
        });
        return response;
    };

    const setDeviceKeyValue = async (row, value, index, e) => {

        //value = e.target.value.replace(/\D/g, "");

        let temp_state = [...KeyListArray];
        let temp_element = { ...temp_state[index] };
        temp_element.device_key_vals = value;
        temp_state[index] = temp_element;
        setKeyListArray(temp_state);

    };

    const setDeviceKeyScale = async (row, value, index) => {
        let temp_state = [...KeyListArray];
        let temp_element = { ...temp_state[index] };
        temp_element.device_key_scale = value;
        temp_state[index] = temp_element;
        setKeyListArray(temp_state);
    };

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Update Keys</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                            }}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                            size="sm">
                                            go back
                                        </Button>
                                    </div>
                                </Row>                                

                                <div className="pl-lg-4 mt-4">                                    
                                        {KeyListArray.length != 0 ?
                                            (KeyListArray.map((row, index) => {
                                                
                                                return (
                                        <Row>
                                            <Col lg="3">
                                                <FormGroup>
                                                    <label className="form-control-label" style={{ marginTop: '40px', color: 'green'}} htmlFor="input-first-name">{row.device_key_name}</label>
                                                    <Input className="form-control-alternative" id="device_key_id" placeholder="Scale Value" type="hidden" value={row.id} />
                                                    <span className="text-danger"></span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="5">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-first-name">Scale Type</label>
                                                                <Select style={{ width: '100%' }}
                                                                    onChange={(e) => setDeviceKeyScale(row, e.target.value, index)}
                                                                    labelId="demo-simple-select-outlined-label" id="device_key_scale" value={row.device_key_scale} >
                                                        <MenuItem value="1">Addition</MenuItem>
                                                        <MenuItem value="2">Subtraction</MenuItem>
                                                        <MenuItem value="3">Multiplication</MenuItem>
                                                        <MenuItem value="4">Division</MenuItem>
                                                    </Select>
                                                    <span className="text-danger"></span>
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <label className="form-control-label" htmlFor="input-first-name">Scale Value</label>
                                                    <Input
                                                                    className="form-control-alternative" id="device_key_vals" placeholder="Scale Value" type="text" value={row.device_key_vals}
                                                                    onChange={(e) => setDeviceKeyValue(row,e.target.value,index,e)}
                                                    />
                                                    <span className="text-danger"></span>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                                );
                                            }))
                                            :
                                            null
                                        }                                                                       

                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={UpdateKeysList}
                                                size="sm">
                                                Update
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;