import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import * as XLSX from 'xlsx';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button, MenuItem, Select,
} from '@material-ui/core';
import swal from 'sweetalert';

const DCSGUpload = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }

    const [plantlist, setplantlist] = useState([]);
    const [plantid, setplantid] = useState("");
    const [PlantError, setPlantError] = useState("");

    const [ExcelData, setExcelData] = useState("");
    const [fileUploadExcelError, setfileUploadExcelError] = useState("");

    const [dateid, setdateid] = useState("1");
    const [DateError, setDateError] = useState("");

    const [date, setDate] = useState(new Date());
    const [DatePickerError, setDatePickerError] = useState("");
    const [ShowDate, setShowDate] = useState(false);

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
        getData();
    }, []);

    const getAllPlantList = async () => {
        const response = await axios.get(`plants/activeplant/`);
        return response;
    };

    const getData = async () => {
        await getAllPlantList().then((response) => {
            if (response.data.status == 400) {
            } else {
                setplantlist(response.data.data)
            }
        });
    };

    const UploadDCSG = async () => {
        const user = {};

        var checkplant = false;

        var checkdate = false;

        var checkfileUploadExcel = false;

        var checkdatenew = true;

        if(ShowDate)
        {

            if(date == "" || date == null)
            {
                checkdatenew = false;
                setDatePickerError("Please select date");
            }
            else {
                checkdatenew =  true;
                setDatePickerError("");
            }
        }

        if (plantid == "") {
            setPlantError("Please select plant name")
        }
        else {
            checkplant = true;
            setPlantError("")
        }

        if (dateid == "") {
            setDateError("Please select upload date by")
        } else {
            checkdate = true;
            setDateError("")
        }

        if (ExcelData == "") {
            setfileUploadExcelError("Please select upload file");
        }
        else {
            checkfileUploadExcel = true;
            setfileUploadExcelError("");
        }

        if (checkplant && checkdate && checkfileUploadExcel && checkdatenew) {
            await UploadDCSGData(user).then((response) => {
                if (response.data.status == 400) {
                    swal(response.data.message, { icon: "error" });
                } else {
                    let timerInterval
                    swal({
                        title: "Data Uploaded Successfully",
                        icon: "success"
                    }).then((result) => {
                        window.location.assign("/admin/dcsg-upload");
                    })
                }
            });
        }
        else {

        }
    };

    const UploadDCSGData = async (user) => {
        const response = await axios.post(`users/uploadDCSGFile`, {
            plant_id: plantid,
            date_id: dateid,
            upload_date: date,
            data_json: ExcelData,
        });
        return response;
    };

    const handleChange = (event) => {
        setplantid(event.target.value);

    };

    const handleDateChange = (event) => {
        setdateid(event.target.value);
        if(event.target.value == 3) {
            setShowDate(true)
        }
        else
        {
            setShowDate(false)
        }
    };

    const handleCalendarClose = () => {
        //console.log("Calendar closed");
    };

    const handleCalendarOpen = () => {
        //console.log("Calendar opened");
    };

    const UploadExcelFile = (event) => {
        const fileUpload = (document.getElementById('fileUploadExcel'));
        const regex = /^([a-zA-Z0-9\s_\\.\-:])+(.xls|.xlsx)$/;
        if (regex.test(fileUpload.value.toLowerCase())) {
            let fileName = fileUpload.files[0].name;
            if (typeof (FileReader) !== 'undefined') {
                const reader = new FileReader();
                if (reader.readAsBinaryString) {
                    reader.onload = (e) => {
                        processExcel(reader.result);
                    };
                    reader.readAsBinaryString(fileUpload.files[0]);
                }
            } else {
                setfileUploadExcelError("This browser does not support HTML5.");
            }
        } else {
            setfileUploadExcelError("Please upload a valid Excel file.");
        }
    };

    const processExcel = (data) => {
        const workbook = XLSX.read(data, {type: 'binary'});
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[firstSheet]);
        setExcelData(JSON.stringify(excelRows))
        //console.log(JSON.stringify(excelRows));
    };

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">DCSG Upload</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                            }}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                            size="sm">
                                            go back
                                        </Button>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-device-name">Select Plant Name</label>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={plantid}
                                                    displayEmpty
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">
                                                        Select Plant Name
                                                    </MenuItem>
                                                    {plantlist.map((value, index) =>
                                                        <MenuItem value={value.id}>{value.plant_name}</MenuItem>
                                                    )}
                                                </Select>
                                                <span className="text-danger">{PlantError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-device-name">Upload Date By</label>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={dateid}
                                                    onChange={handleDateChange}
                                                >
                                                    <MenuItem value="1">
                                                        Real Time
                                                    </MenuItem>
                                                    <MenuItem value="2">
                                                        Day Head
                                                    </MenuItem>
                                                    <MenuItem value="3">
                                                        By Date
                                                    </MenuItem>
                                                </Select>
                                                <span className="text-danger">{DateError}</span>
                                            </FormGroup>
                                        </Col>
                                        {ShowDate == true ?
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-device-name">Date Range:</label>
                                                        <div className="col text-left pl-0">
                                                            <DatePicker
                                                            dateFormat="dd-MM-yyyy"
                                                            selected={date}
                                                            maxDate={new Date()}
                                                            onChange={(date) =>{
                                                            setDate(date)
                                                                /*console.log('Date : ' + date)*/
                                                            }}
                                                            onCalendarClose={handleCalendarClose}
                                                            onCalendarOpen={handleCalendarOpen}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                    <span className="text-danger">{DatePickerError}</span>
                                            </Col>
                                            :<Col lg="4"></Col>
                                        }

                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-device-name">Upload File: <span className="text-danger">(File extensions allowed are : (.XLSX , .XLS)</span></label>
                                                <div className="col text-left pl-0">
                                                    <input className="upload-excel" type="file" id="fileUploadExcel" onChange={UploadExcelFile}/>
                                                </div>
                                                <span className="text-danger">{fileUploadExcelError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-device-name"></label>
                                                <div className="col text-left pl-0">
                                                    <a
                                                        title={'Download Sample File'}
                                                        href={'https://monitoring.unilinkgroup.in/Sample_Upload.xlsx'}
                                                        className="btn btn-success"
                                                        style={{
                                                            backgroundColor: "#28c76f", color: "#fff", BorderColor: "#28c76f"
                                                        }}
                                                        color="success"
                                                        onClick={''}
                                                        size="sm" target={'new'}>
                                                        <i className="fa fa-download text-white"></i>  Download Sample File
                                                    </a>
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={UploadDCSG}
                                                size="sm">
                                                Upload
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default DCSGUpload;