
import CustomerIndex from "views/customerindex";
import Index from "views/Index";
import Profile from "views/examples/Profile.js";
import CustomerProfile from "views/examples/Customer/profile";
import ChangePassword from "views/examples/Customer/changepassword";
/*import Maps from "views/examples/Maps.js";*/
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Users from "views/examples/User/UserList.js";
import EditUser from "views/examples/User/edituser.js";
import AddUser from "views/examples/User/adduser.js";
import DCSGUpload from "views/examples/User/dcsgupload.js";
import Device from "views/examples/Device/device.js";
import AddDevice from "views/examples/Device/adddevice.js";
import EditDevice from "views/examples/Device/editdevice";
import AdminList from "views/examples/Admin/AdminList";
import AddAdmin from "views/examples/Admin/addadmin";
import EditAdmin from "views/examples/Admin/editadmin";
import UserDevice from "views/examples/Device/userdevicelist";
import UserKeys from "views/examples/Device/userkeylist";
import DCSGDownload from "views/examples/User/dcsgdownload.js";
import Inverter from "views/examples/Inverter/inverter"
import AddInverter from "views/examples/Inverter/addinverter"
import EditInverter from "views/examples/Inverter/editinverter"
import Plant from "views/examples/Plant/plantlist"
import AddPlant from "views/examples/Plant/addplant"
import EditPlant from "views/examples/Plant/editplant"
import UserDeviceDetail from "views/examples/User/UserDeviceDetails"
import DeviceDetail from "views/examples/User/devicedetail.js";

import Certificate from "views/examples/Certificate/certificatelist";
import AddCertificate from "views/examples/Certificate/addcertificate";
import EditCertificate from "views/examples/Certificate/editcertificate"

import CustomerSetting from "views/examples/Setting/Setting.js";

import Icons from "views/examples/Icons.js";
import UserDeviceDetailsByCustomer from "./views/examples/User/UserDeviceDetailsByCustomer";


var routes = [
    {
        path: "/profile",
        name: "Profile",
        icon: "ni ni-tv-2 text-blue",
        component: CustomerProfile,
        layout: "/customer",
    },
    {
        path: "/changepassword",
        name: "ChangePassword",
        icon: "ni ni-tv-2 text-blue",
        component: ChangePassword,
        layout: "/customer",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-blue",
        component: CustomerIndex,
        layout: "/customer",
    },
    {
        path: "/devicedetail",
        name: "devicedetail",
        icon: "ni ni-tv-2 text-blue",
        component: DeviceDetail,
        layout: "/customer",
    },
    {
        path: "/userdevicedetail",
        name: "user device detail",
        icon: "ni ni-tv-2 text-blue",
        component: UserDeviceDetail,
        layout: "/admin",
    },
    {
        path: "/userdevicedetailbycustomer",
        name: "user device detail customer",
        icon: "ni ni-tv-2 text-blue",
        component: UserDeviceDetailsByCustomer,
        layout: "/admin",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "ni ni-tv-2 text-blue",
        component: Index,
        layout: "/admin",
    },
    {
        path: "/admins",
        name: "Admin Management",
        icon: "ni ni-circle-08 text-blue",
        component: AdminList,
        layout: "/admin",
    },
    {
        path: "/addadmin",
        name: "add admin",
        icon: "ni ni-bullet-list-67 text-blue",
        component: AddAdmin,
        layout: "/admin",
    },
    {
        path: "/editadmin",
        name: "edit admin",
        icon: "ni ni-bullet-list-67 text-blue",
        component: EditAdmin,
        layout: "/admin",
    },
    {
        path: "/users",
        name: "Customer Management",
        icon: "ni ni-circle-08 text-blue",
        component: Users,
        layout: "/admin",
    },
    {
        path: "/plant",
        name: "Plant",
        icon: "ni ni-pin-3 text-blue",
        component: Plant,
        layout: "/admin",
    },
    {
        path: "/addplant",
        name: "Add Plant",
        icon: "ni ni-pin-3 text-blue",
        component: AddPlant,
        layout: "/admin",
    },
    {
        path: "/editplant",
        name: "Edit Plant",
        icon: "ni ni-pin-3 text-blue",
        component: EditPlant,
        layout: "/admin",
    },
    {
        path: "/certificate",
        name: "Certificate Management",
        icon: "ni ni-bullet-list-67 text-blue",
        component: Certificate,
        layout: "/admin",
    },
    {
        path: "/addcertificate",
        name: "Add Certificate",
        icon: "ni ni-pin-3 text-blue",
        component: AddCertificate,
        layout: "/admin",
    },
    {
        path: "/editcertificate",
        name: "Edit Certificate",
        icon: "ni ni-pin-3 text-blue",
        component: EditCertificate,
        layout: "/admin",
    },
    {
        path: "/adddevice",
        name: "add device",
        icon: "ni ni-planet text-blue",
        component: AddDevice,
        layout: "/admin",
    },
    {
        path: "/editdevice",
        name: "edit device",
        icon: "ni ni-planet text-blue",
        component: EditDevice,
        layout: "/admin",
    },
    {
        path: "/device",
        name: "Device Management",
        icon: "ni ni-planet text-blue",
        component: Device,
        layout: "/admin",
    },
    {
        path: "/userdevice",
        name: "user device",
        icon: "ni ni-planet text-blue",
        component: UserDevice,
        layout: "/admin",
    },
    {
        path: "/userkeys",
        name: "Update Keys",
        icon: "ni ni-planet text-blue",
        component: UserKeys,
        layout: "/admin",
    },
    
    //{
    //    path: "/inverter",
    //    name: "inverter",
    //    icon: "ni ni-planet text-blue",
    //    component: Inverter,
    //    layout: "/admin",
    //},
    //{
    //    path: "/addinverter",
    //    name: "addinverter",
    //    icon: "ni ni-planet text-blue",
    //    component: AddInverter,
    //    layout: "/admin",
    //},
    
    //{
    //    path: "/editinverter",
    //    name: "editinverter",
    //    icon: "ni ni-planet text-blue",
    //    component: EditInverter,
    //    layout: "/admin",
    //},
    {
        path: "/edituser",
        name: "edit user",
        icon: "ni ni-bullet-list-67 text-blue",
        component: EditUser,
        layout: "/admin",
    },
    {
        path: "/adduser",
        name: "add user",
        icon: "ni ni-bullet-list-67 text-blue",
        component: AddUser,
        layout: "/admin",
    },
    {
        path: "/login",
        name: "Login",
        icon: "ni ni-key-25 text-blue",
        component: Login,
        layout: "/auth",
    },
    {
        path: "/setting",
        name: "Setting Management",
        icon: "ni ni-settings-gear-65 text-blue",
        component: CustomerSetting,
        layout: "/admin",
    },
    {
        path: "/dcsg-upload",
        name: "DCSG Upload",
        icon: "ni ni-cloud-upload-96 text-blue",
        component: DCSGUpload,
        layout: "/admin",
    },
    {
        path: "/dcsg-download",
        name: "DCSG Download",
        icon: "ni ni-cloud-download-95 text-blue",
        component: DCSGDownload,
        layout: "/admin",
    },
];

export default routes;