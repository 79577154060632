import React, { useState, useEffect } from "react";
import {
    Button,
    Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    CardTitle,
    Label, ModalHeader, ModalBody, Col, FormGroup, ModalFooter, Modal,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import SearchBar from 'material-ui-search-bar';
import {
    TablePagination,
    TableFooter,
    colors,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box, Select, MenuItem,

} from '@material-ui/core';
import { BorderColor } from "@material-ui/icons";
import swal from 'sweetalert';
import DataPagination from "react-js-pagination";
import {makeStyles} from "@material-ui/core/styles";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment";
import {BASE_URL} from "../../../backend";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles(theme => ({
    modal: {
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 150,
        marginRight: 150,
        maxHeight: '100%',
        overflow: 'auto',
        width: '80%',
    },
    paper: {
        //backgroundColor: theme.palette.background.paper,
        backgroundColor: '#086477',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
    },
}));

const DCSGDownload = () => {
    const history = useHistory();
    const classes = useStyles();
    const [user, setUser] = useState(getUserDetails());
    const [DGlist, setDGlist] = useState([]);
    const [searched, setSearched] = useState('');
    const [DGlistFromServer, setDGlistFromServer] = useState([]);
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [page, setPage] = useState(0);

    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const [plantlist, setplantlist] = useState([]);
    const [plantid, setplantid] = useState("");
    const [PlantError, setPlantError] = useState("");
    const [isDownLoading, setIsDownloading] = useState(false);
    const [NoDataError, setNoDataError] = useState("");

    const [date, setDate] = useState(new Date());
    const [DatePickerError, setDatePickerError] = useState("");
    const [ShowDate, setShowDate] = useState(false);

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
        getData();
    }, []);

    const getAllDGList = async () => {
        const response = await axios.get(`users/getAccuracyReport`);
        return response;
    };

    const handleChangePage = (event, page) => {
        setPage(page);
    };

    const handleChangeRowsPerPage = event => {
        setrowsPerPage(event.target.value);
    };

    const getData = async () => {
        await getAllDGList().then((response) => {
            if (response.data.status == 400) {
            } else {
                //console.log(response.data.data)
                setDGlist(response.data.data);
                setDGlistFromServer(response.data.data);
            }
        });

        await getAllPlantList().then((response) => {
            if (response.data.status == 400) {
            } else {
                setplantlist(response.data.data)
            }
        });
    };

    const cancelSearch = () => {
        setSearched('');
        setDGlist(DGlistFromServer);
    };
    const isNumeric = value => {
        return /^-?\d+$/.test(value);
    };
    const requestSearch = searchedVal => {
        //console.log('searchedVal : ' + searchedVal)
        if (searchedVal == '') {
            //console.log('if')
            setDGlist(DGlistFromServer);
        } else {
            //console.log('else')
            const filteredRows = DGlist.filter(row => {
                if (isNumeric(searchedVal)) {
                    if (row.phone != null)
                        return (
                            row.phone.startsWith(searchedVal)
                        );
                }
                else {
                    return (
                        row.plant_name.toLowerCase().includes(searchedVal.toLowerCase())
                    );
                }
            });
            setDGlist(filteredRows);
        }
    };

    const handleChangePlantId = (event) => {
        setplantid(event.target.value);
        setPlantError('');
    };

    const onCloseModal = (event) => {
        setplantid('');
        setNoDataError('');
        setModal(false);
    };

    const getAllPlantList = async () => {
        const response = await axios.get(`plants/activeplant/`);
        return response;
    };

    const handleCalendarClose = () => {
        //console.log("Calendar closed");
    };

    const handleCalendarOpen = () => {
        //console.log("Calendar opened");
    };

    const openInNewTab = async (url,excelFileName) => {
        fetch(url, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", excelFileName);
                    document.body.appendChild(link);
                    link.click();
                });
                setIsDownloading(false);
            })
            .catch(err => {
                //console.log(err);\
                setIsDownloading(false);
            });
    };

    const btnDownloadTap = async () => {

        if(plantid == ''){
            setPlantError('Please select plant name');
        }

        if(date == ''){
            setPlantError('Please select date');
        }

        if(plantid != '' && date != ''){
            const response = await axios.post(`users/getDownloadAccuracyReport`, {
                plant_id: plantid,
                start_date: date,
                end_date: date
            });

            if(response.data.file_name == ""){
                setNoDataError('No Records Found');
            }else{
                setIsDownloading(true);
                //console.log("Download Response : "+JSON.stringify(response.data));
                setTimeout(() => {
                    openInNewTab(BASE_URL+"upload/dcsgreport/"+response.data.file_name, response.data.file_name);
                    setModal(false);
                    getData();
                },3000);

            }
        }
    }

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col text-right mb-4">
                        <i title="download" onClick={() => toggle()} style={{ color: 'white', cursor: 'pointer' }} className="fa fa-download btn btn-white text-dark" />
                    </div>
                </Row>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">DG Download</h3>
                                    </div>
                                    <div className="col text-right">

                                    </div>
                                </Row>
                            </CardHeader>

                            <Box component="div" mt={5} mb={5} ml={5} mr={5}>
                                <SearchBar
                                    style={{ height: 'auto' }}
                                    value={searched}
                                    onChange={searchVal => requestSearch(searchVal)}
                                    onCancelSearch={() => cancelSearch()}
                                />
                            </Box>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th scope="col">No</th>
                                    <th scope="col">Plant Name</th>
                                    <th scope="col" style={{ textAlign: "center"}}>Report Date</th>
                                    <th scope="col" style={{ textAlign: "center"}}>Download Report</th>
                                </tr>
                                </thead>
                                <tbody>
                                {DGlist.length != 0 ?
                                    (DGlist.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {

                                        var file_name = "https://monitoring.unilinkgroup.in/backend/solarbackend/public/upload/dcsgreport/"+row.file_name;

                                        return (
                                            <tr>
                                                <td scope="row">{page * rowsPerPage + index + 1}</td>
                                                <td>{row.plant_name}</td>
                                                <td style={{ textAlign: "center"}}><Moment format="DD-MM-YYYY">{row.download_date}</Moment></td>
                                                <td scope="row" style={{ textAlign: "center"}}>
                                                    <a href={file_name} target="new">
                                                        <i className="ni ni-cloud-download-95 text-blue" style={{fontSize: '30px'}}></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    }))
                                    :
                                    <tr><td colSpan="7" style={{ textAlign: "center", fontSize: 20 }}>No Record Found</td></tr>
                                }
                                </tbody>
                                <TableFooter>
                                    <TablePagination
                                        className="customFontFamily"
                                        count={DGlist.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableFooter>
                            </Table>
                        </Card>
                    </div>
                </Row>

                <Modal isOpen={modal} toggle={toggle} centered={true} onClosed={() => onCloseModal()}  backdrop={"static"}>
                    <ModalHeader toggle={toggle}>DOWNLOAD REPORT</ModalHeader>
                    {/*<span>DOWNLOAD REPORT</span>*/}
                    <ModalBody>
                        <div className={isDownLoading == true ? "animate-gradle ": "card-stats mb-xl-0"}>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-device-name">Plant Name:</label>
                                        <Select
                                            style={{ width: '100%' }}
                                            labelId="demo-simple-select-outlined-label"
                                            id="demo-simple-select-outlined"
                                            value={plantid}
                                            displayEmpty
                                            onChange={(event) => handleChangePlantId(event)}
                                        >
                                            <MenuItem value="">
                                                <em>Select plant name</em>
                                            </MenuItem>
                                            {plantlist.map((value, index) =>
                                                <MenuItem value={value.id}>{value.plant_name}</MenuItem>
                                            )}
                                        </Select>
                                        <span className="text-danger">{PlantError}</span>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <FormGroup>
                                        <label className="form-control-label" htmlFor="input-device-name">Date Range:</label>
                                        <div className="col text-left pl-0">
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                selected={date}
                                                maxDate={new Date()}
                                                onChange={(date) =>{
                                                    setDate(date)
                                                }}
                                                onCalendarClose={handleCalendarClose}
                                                onCalendarOpen={handleCalendarOpen}
                                            />
                                        </div>
                                    </FormGroup>
                                    <span className="text-danger">{DatePickerError}</span>
                                </Col>
                            </Row>
                            <Row>
                                <span className="text-danger text-center w-100 font-weight-bolder">{NoDataError}</span>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={isDownLoading} onClick={() => btnDownloadTap()}>Download</Button>{' '}
                    </ModalFooter>
                </Modal>
            </Container>
        </>
    );
};

export default DCSGDownload;