import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button,
} from '@material-ui/core';
import swal from 'sweetalert';

const Setting = (props) => {

    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }
    const [MinDcPower, setMinDcPower] = useState("");
    const [MinDcPowerError, setMinDcPowerError] = useState("");

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
        getData();
    }, []);
    const getData = async () => {
        await getUserData().then((response) => {
            if (response.data.status == 400) {

            } else {
                console.log(response.data.data)
                setMinDcPower(response.data.data.min_dc_power)
            }
        });
    };

    const getUserData = async () => {

        const response = await axios.get(`users/getSetting`);
        return response;
    };

    const UpdateUser = async () => {
        const user = {};

        var checkMinDcPower = false;


        if (MinDcPower == "") {
            setMinDcPowerError("Please enter first name")
        }
        else {
            user.MinDcPower = MinDcPower;
            checkMinDcPower = true;
            setMinDcPowerError("")
        }

        if (checkMinDcPower) {
            await updateUser(user).then((response) => {
                if (response.data.status == 400) {
                    swal(response.data.message, { icon: "error" });
                } else {
                    //window.location.assign("/admin/users");
                    //history.goBack();
                    //console.log('Profile update : ' + JSON.stringify(response.data))
                    swal(response.data.message, { icon: "success" });
                }
            });
        }
        else {

        }
    };

    const updateUser = async (users) => {
        const response = await axios.post(`users/updateSettingValue`, {
            min_dc_power: users.MinDcPower
        });
        return response;
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Setting Management</h3>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">Min Dc Power</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="First Name"
                                                    type="text"
                                                    onChange={(e) => setMinDcPower(e.target.value)}
                                                    value={MinDcPower}
                                                />
                                                <span className="text-danger">{MinDcPowerError}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={UpdateUser}
                                                size="sm">
                                                Update
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Setting;
