import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button,
} from '@material-ui/core';
import swal from 'sweetalert';

const Profile = (props) => {

    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }
    const [OldPassword, setOldPassword] = useState("");
    const [NewPassword, setNewPassword] = useState("");
    const [ConfirmPassword, setConfirmPassword] = useState("");
    const [OldPasswordError, setOldPasswordError] = useState("");
    const [NewPasswordError, setNewPasswordError] = useState("");
    const [ConfirmPasswordError, setConfirmPasswordError] = useState("");    
    const [ComparePasswordError, setComparePasswordError] = useState("");    
   
    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
    }, []);
   
    const UpdateUser = async () => {
        const user = {};
        var checkoldpassword = false;
        var checknewpassword = false;
        var checkconfirmpassword = false;
        var checkcomparepassword = false;
        
        if (OldPassword == "") {
            setOldPasswordError("Please enter old password");
        }
        else {
            user.old_password = OldPassword;
            checkoldpassword = true;
            setOldPasswordError("");
        }
        if (NewPassword == "") {
            setNewPasswordError("Please enter new password");
        }
        else {
            user.new_password = NewPassword
            checknewpassword = true;
            setNewPasswordError("");
        }
        if (ConfirmPassword == "") {
            setConfirmPasswordError("Please enter confirm password");
        }
        else {
            checkconfirmpassword = true;
            setConfirmPasswordError("");
        }
        if (NewPassword != ConfirmPassword) {
            setComparePasswordError("New password and confirm password not matched");
        }
        else {
            setComparePasswordError("")
            checkcomparepassword = true;
        }
        if (checkoldpassword && checknewpassword && checkconfirmpassword && checkcomparepassword) {
            await updateUser(user).then((response) => {
                if (response.data.status == 400) {
                    swal(response.data.message, { icon: "error" });
                } else {
                    //window.location.assign("/admin/users");
                    //history.goBack();
                    swal(response.data.message, { icon: "success" });
                }
            });
        }
        else {

        }
    };
    const updateUser = async (users) => {
        const response = await axios.post(`users/changePasswordByCustomer`, {
            user_id: user._id,
            old_password: users.old_password,
            new_password: users.new_password,            
        });
        return response;
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Change Password</h3>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">Old Password</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="Old Password"
                                                    type="password"
                                                    onChange={(e) => setOldPassword(e.target.value)}
                                                    value={OldPassword}
                                                />
                                                <span className="text-danger">{OldPasswordError}</span>
                                            </FormGroup>
                                        </Col>
                                       
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">New Password</label>
                                                <Input
                                                    className="form-control-alternative"

                                                    id="input-email"
                                                    placeholder="New Password"
                                                    type="password"
                                                    value={NewPassword}                                                    
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                />
                                                <span className="text-danger">{NewPasswordError}</span>
                                            </FormGroup>
                                        </Col>
                                       
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-phone">Confirm Password</label>
                                                <Input
                                                    className="form-control-alternative"

                                                    id="input-phone"
                                                    placeholder="Confirm Password"
                                                    type="password"
                                                    value={ConfirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                />
                                                <div>
                                                    <span className="text-danger">{ConfirmPasswordError}</span>
                                                </div><div>
                                                    <span className="text-danger">{ComparePasswordError}</span>
                                                    </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={UpdateUser}
                                                size="sm">
                                                Update
                                    </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Profile;
