import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
// import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col, Modal, ModalHeader, ModalBody, ModalFooter,
    CardTitle,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem, FormGroup, Input,
} from "reactstrap";

// core components
import {
    chartOptions,
    parseOptions,
    chartExample1,
    chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../axios";
import Moment from 'react-moment';
import { useHistory } from 'react-router';
import createDOMPurify from 'dompurify'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import {MenuItem, Select} from "@material-ui/core";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {BASE_URL} from "../backend";
const useStyles = makeStyles(theme => ({
    modal: {
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 150,
        marginRight: 150,
        maxHeight: '100%',
        overflow: 'auto',
        width: '80%',
    },
    paper: {
        //backgroundColor: theme.palette.background.paper,
        backgroundColor: '#086477',
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
    },
}));

const Index = (props) => {


    const [user, setUser] = useState(getUserDetails());
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    const [plantlist, setplantlist] = useState([]);
    const [plantid, setplantid] = useState("");
    const [PlantError, setPlantError] = useState("");

    const [dashplantid, setdashplantid] = useState("");

    const [clientList, setclientList] = useState([]);
    const [clientId, setclientId] = useState("");
    const [ClientError, setClientError] = useState("");

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }else if(user.role != 1){
            window.location.assign("/customer/dashboard");
        }
        else{

        }
        getData();
    }, []);

    const DOMPurify = createDOMPurify(window)
    const history = useHistory();
    const classes = useStyles();
    const [activeNav, setActiveNav] = useState(1);
    const [chartExample1Data, setChartExample1Data] = useState("data1");

    const [devicelist, setdevicelist] = useState([]);

    const [inverterList, setinverterList] = useState([]);
    const [inverterId, setinverterId] = useState('');

    const [dashboardlist, setdashboardlist] = useState([]);

    const [userslist, setuserslist] = useState([]);
    const [download, setdownload] = useState(false);
    const [downloaddata, setdownloaddata] = useState(null);

    const [isLoading, setIsloading] = useState(false);
    const [isDownLoading, setIsDownloading] = useState(false);
    const [DateRange, setDateRange] = useState([new Date(), new Date()]);
    const [NoDataError, setNoDataError] = useState("");

    if (window.Chart) {
        parseOptions(Chart, chartOptions());
    }

    const handleOpen = (data) => {
        setdownloaddata(data)
        setdownload(true);
    };
    const handleClose = () => {
        setdownload(false);
    };

    const getAllDashboard = async () => {
        const response = await axios.get(`users/dashboard`);
        return response;
    };

    const getDashAllUserList = async (dash_plant_id) => {
        const response = await axios.get(`users/getDeviceLastDCPower?plant_id=`+dash_plant_id);
        return response;
    };

    const getAllUserList = async () => {
        var tempid = localStorage.getItem('setdashplantid');
        const response = await axios.get(`users/getDeviceLastDCPower?plant_id=`+tempid);
        return response;
    };

    const getDataHomePage = async () => {
        getAllUserList().then((response) => {
            setIsloading(true)
            if (response.data.status == 400) {
            } else {
                setuserslist(response.data.data);
                setIsloading(false)
            }
        });
    }

    const getData = async () => {

        await axios.get(`plants/activeplant/`).then((response) =>{
            if (response.data.status == 400) {
            } else {
                var data = response.data.data
                setplantlist(response.data.data);
                setdashplantid(data[0].id);
                localStorage.setItem('setdashplantid',data[0].id)
                if(data.length != 0){
                    getDataHomePage();
                }
            }
        });

        /*await getAllClientList().then((response) => {
            if (response.data.status == 400) {
            } else {
                setclientList(response.data.data)
            }
        });*/

        // For Regular Interval
        setInterval( ()=>{
            getAllUserList().then((response) => {
                    if (response.data.status == 400) {
                    }else if(response.data.status == 500){

                    }else {
                        //console.log(response.data.data)
                        setuserslist(response.data.data);
                    }

                });

            //console.log("DC Power Call");
        },15000);
    };

    const getAllPlantList = async () => {
        const response = await axios.get(`plants/activeplant/`);
        return response;
    };

    /*const getAllClientList = async () => {
        const response = await axios.get(`users?role=2`);
        return response;
    };*/

    const toggleNavs = (e, index) => {
        e.preventDefault();
        setActiveNav(index);
        setChartExample1Data("data" + index);
    };

    const isDateTimeCheck = (date) => {
        var now_date_time = moment(new Date());

        var input_date = moment(date);

        var is_diff_hrs = now_date_time.diff(input_date, 'hours');

        if(is_diff_hrs > 1){
            return true;
        }else {
            return false;
        }
    }

    const ChangeDashPlantId = (event) => {
        setdashplantid(event.target.value);
        localStorage.setItem('setdashplantid',event.target.value)
        // setPlantError('');

        getAllUserList().then((response) => {
            setIsloading(true)
            if (response.data.status == 400) {
            } else {
                //console.log(response.data.data);
                setuserslist(response.data.data);
                setIsloading(false)
            }
        });
    };

    const handleChangePlantId = async (event) => {
        console.log(event.target.value);
        setPlantError('');
        if(event.target.value === '' || event.target.value === null){
            setclientList([]);
            setplantid(event.target.value);
        }else{
            setplantid(event.target.value);
            const response = await axios.get(`users/getClientByPlant?plant_id=`+event.target.value);
            setclientList(response.data.data);
        }
    };

    const handleChangeClientId = async (event) => {
        setClientError('');
        //console.log(event.target.value)

        if(event.target.value === '' || event.target.value === null){
            setinverterList([]);
            setclientId(event.target.value);
        }else {
            setclientId(event.target.value);
            const response = await axios.get(`devices/bycustomer?user_id=` + event.target.value);
            //console.log("Inverter Response : "+JSON.stringify(response.data.data))
            setinverterList(response.data.data);
        }
    };

    const handleChangeInverterId = async (event) => {
        setinverterId(event.target.value);
    }

    const SearchDateRangeData = (value) => {
        setDateRange(value);
    };

    const onCloseModal = (event) => {
        setinverterId('');
        setclientId('');
        setplantid('');
        setNoDataError('');
        setModal(false);
    };

    const openInNewTab = async (url,excelFileName) => {
        fetch(url, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", excelFileName);
                    document.body.appendChild(link);
                    link.click();
                });
                setIsDownloading(false);
            })
            .catch(err => {
                //console.log(err);\
                setIsDownloading(false);
            });
    };

    const btnDownloadTap = async () => {

        if(plantid == ''){
            setPlantError('Please select plant name');

        }
        if(clientId == '') {
            setClientError('Please select client name');

        }

        if(plantid != '' && clientId){

            const response = await axios.post(`users/getDownloadPowerReport`, {
                plant_id: plantid,
                user_id: clientId,
                device_id: inverterId,
                start_date: moment(DateRange[0]).format("YYYY/MM/DD"),
                end_date: moment(DateRange[1]).format("YYYY/MM/DD")
            });

            if(response.data.file_name == ""){
                setNoDataError('No Records Found');
            }else{
                setIsDownloading(true);
                setTimeout(() => {
                    openInNewTab(BASE_URL+"upload/report/"+response.data.file_name, response.data.file_name);
                    setModal(false);
                },3000);

            }

            //console.log("Download Response : "+JSON.stringify(response.data.file_name))
        }
    }

    return (
        <>
            {/* <Header />*/}
            <div className="header bg-gradient-info bg-gradient-info-vh pb-4 pt-5 pt-md-6">
                <Container fluid>
                    <div className="header-body">
                        {/* Card stats */}
                        <Row>
                            <div className="col text-left mb-4 col-md-3">
                                <Select
                                    style={{ color: 'white', width: '100%'}}
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={dashplantid}
                                    onChange={(event) => ChangeDashPlantId(event)}
                                >
                                    {plantlist.map((value, index) =>
                                        <MenuItem value={value.id}>{value.plant_name}</MenuItem>
                                    )}
                                </Select>
                            </div>
                            <div className="col text-right mb-4 col-md-7">
                            </div>
                            <div className="col text-right mb-4 col-md-2">

                                <i title="Refresh" onClick={() => {
                                    getDataHomePage()
                                }} style={{ color: 'white', cursor: 'pointer' }} className="fa fa-sync-alt btn btn-white text-dark" />


                                <i title="download" onClick={() => toggle()} style={{ color: 'white', cursor: 'pointer' }} className="fa fa-download btn btn-white text-dark" />

                            </div>
                        </Row>
                        <Row>
                            {userslist.length != 0 ?
                                (userslist.map((row, index) => {
                                    //console.log(JSON.stringify(row));
                                    return (
                                        <Col className="mb-3 pl-2 pr-2" md="3">
                                            <Card className={isLoading == true ? "animate-gradle ": "card-stats mb-xl-0"} onClick={() => {
                                                if(!isLoading){
                                                    history.push(`/admin/userdevicedetail`, row)
                                                }

                                            }}
                                                  style={{ cursor: 'pointer' }}>
                                                <CardBody className="d-flex align-items-center statbox-body">

                                                        <div className="col p-0" style={{ textAlign: 'center' }}>
                                                            <CardTitle
                                                                tag="h3" className="font-weight-bold text-uppercase mb-3"
                                                            >
                                                                <span>{row.company_name}</span>
                                                            </CardTitle>

                                                            <div className="mb-3">
                                                                <span className={isDateTimeCheck(row.device_timestamp_at) ? "h1 mt-0 cardbox-title text-danger" : "h1 mt-0 cardbox-title"} >{row.dc_power_average}<sub>MW</sub></span>
                                                            </div>

                                                            <div className="mb-10">
                                                                <span className="h5 mt-0 text-xs text-muted">Last Updated: {moment(row.device_timestamp_at).format("DD-MM-YYYY hh:mm:s")}</span>
                                                            </div>
                                                        </div>
                                                        {/*
                                                            <UncontrolledDropdown>
                                                                <DropdownToggle
                                                                    className="btn-icon-only text-light"
                                                                    role="button"
                                                                    size="sm"
                                                                    color=""
                                                                    onClick={(e) => e.preventDefault()}
                                                                >
                                                                    <i className="fas fa-ellipsis-v" />
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-arrow" right>
                                                                    <DropdownItem><i class="fa fa-download"></i>Download</DropdownItem>
                                                                    <DropdownItem><i class="fas fa-arrow-alt-circle-right"></i>Explore Data</DropdownItem>
                                                                    <DropdownItem><i class="fa fa-search-plus"></i>Enlarge</DropdownItem>
                                                                    <DropdownItem><i class="fa fa-copy"></i>Duplicate</DropdownItem>
                                                                    <DropdownItem><i class="fa fa-cog"></i>Edit</DropdownItem>
                                                                    <DropdownItem><i class="fa fa-download"></i>Export PNG</DropdownItem>
                                                                    <DropdownItem><i class="fa fa-trash"></i>Delete</DropdownItem>
                                                                </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                        */}

                                                        {/*<p className="mt-3 mb-0 text-muted text-sm">
                                                            <span className="text-success mr-2">
                                                                <i className="fa" />
                                                            </span>{" "}
                                                            <span className="text-nowrap"></span>
                                                        </p>*/}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    );
                                }))
                                :
                                null
                            }

                            {download != false ?
                                <Modal
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={download}
                                    onClose={handleClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}>
                                    <Fade in={download}>
                                        <div className={classes.paper}>
                                            <h2 id="transition-modal-title">Download data</h2>
                                            <h2 id="transition-modal-title">{downloaddata.first_name} {downloaddata.last_name}</h2>
                                        </div>
                                    </Fade>
                                </Modal>
                                : null}

                            <Modal isOpen={modal} toggle={toggle} centered={true} onClosed={() => onCloseModal()}  backdrop={"static"}>
                                <ModalHeader toggle={toggle}>DOWNLOAD REPORT</ModalHeader>
                                {/*<span>DOWNLOAD REPORT</span>*/}
                                <ModalBody>
                                    <div className={isDownLoading == true ? "animate-gradle ": "card-stats mb-xl-0"}>
                                            <Row>
                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-device-name">Plant Name:</label>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={plantid}
                                                            displayEmpty
                                                            onChange={(event) => handleChangePlantId(event)}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select plant name</em>
                                                            </MenuItem>
                                                            {plantlist.map((value, index) =>
                                                                <MenuItem value={value.id}>{value.plant_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                        <span className="text-danger">{PlantError}</span>
                                                    </FormGroup>
                                                </Col>

                                                <Col lg="12">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-device-name">Client Name:</label>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={clientId}
                                                            displayEmpty
                                                            onChange={(event) => handleChangeClientId(event)}
                                                        >
                                                            <MenuItem value="">
                                                                <em>Select client name</em>
                                                            </MenuItem>
                                                            {clientList.map((value, index) =>
                                                                <MenuItem value={value.id}>{value.company_name}</MenuItem>
                                                            )}
                                                        </Select>
                                                        <span className="text-danger">{ClientError}</span>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="5">
                                                    <FormGroup>
                                                        <label className="form-control-label" htmlFor="input-device-name">Device Name:</label>
                                                        <Select
                                                            style={{ width: '100%' }}
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={inverterId}
                                                            displayEmpty
                                                            onChange={(event) => handleChangeInverterId(event)}
                                                        >
                                                            <MenuItem value="">
                                                                <em>All</em>
                                                            </MenuItem>
                                                            {inverterList.length != 0 ?
                                                                (inverterList.map((value, index) =>
                                                                <MenuItem value={value.id}>{value.device_name}</MenuItem>
                                                            ))
                                                                :null}
                                                        </Select>
                                                        {/*<span className="text-danger">{PlantError}</span>*/}
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label className="form-control-label">Date Range:</label>
                                                        <DateRangePicker className="dcsolardatepicker"
                                                                         style={{ width: '100%' }}
                                                                         onChange={(value) => {
                                                                             SearchDateRangeData(value)
                                                                         }}
                                                                         value={DateRange}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <span className="text-danger text-center w-100 font-weight-bolder">{NoDataError}</span>
                                            </Row>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" disabled={isDownLoading} onClick={() => btnDownloadTap()}>Download</Button>{' '}
                                    {/*<Button color="secondary" onClick={(event) => onCloseModal(event)}>Close</Button>*/}
                                </ModalFooter>
                            </Modal>
                        </Row>
                    </div>
                </Container>
            </div>

        </>
    );
};

export default Index;