import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button,
} from '@material-ui/core';
import swal from 'sweetalert';

const Tables = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }
    const [CertificateId, setCertificateId] = useState(props.location.state.id);

    const [DeviceName, setDeviceName] = useState(props.location.state.device_name);
    const [DeviceNameError, setDeviceNameError] = useState("");

    const [CertificateUrl, setCertificateUrl] = useState(props.location.state.certificate_url);
    const [CertificateUrlError, setCertificateUrlError] = useState("");

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
    }, []);

    const AddCertificate = async () => {

        var checkDeviceName = false;
        var checkCertificateUrl = false;

        if (DeviceName == "") {
            setDeviceNameError("Please enter device name");
        }
        else {
            checkDeviceName = true;
            setDeviceNameError("");
        }

        if (CertificateUrl == "") {
            setCertificateUrlError("Please enter Certificate Url")
        }
        else {
            checkCertificateUrl = true;
            setCertificateUrlError("")
        }

        if (checkDeviceName && checkCertificateUrl) {
            await AddNewCertificate(user).then((response) => {
                if (response.data.status == 400) {
                    swal(response.data.message, { icon: "error" });
                } else {
                    //swal(response.data.message, { icon: "success" });
                    history.goBack();
                }
            });
        }
        else {

        }
    };
    const AddNewCertificate = async (user) => {
        const response = await axios.post(`devices/certificateUpdate`, {
            certificate_id: CertificateId,
            device_name: DeviceName,
            certificate_url: CertificateUrl,
        });
        return response;
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Edit Certificate</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                            }}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                            size="sm">
                                            go back
                                    </Button>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">Device Name</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="Device Name"
                                                    type="text"
                                                    onChange={(e) => setDeviceName(e.target.value)}
                                                    value={DeviceName}
                                                />
                                                <span className="text-danger">{DeviceNameError}</span>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">Certificate Url</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="Certificate Url"
                                                    type="text"
                                                    onChange={(e) => setCertificateUrl(e.target.value)}
                                                    value={CertificateUrl}
                                                />
                                                <span className="text-danger">{CertificateUrlError}</span>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={AddCertificate}
                                                size="sm">
                                                Update
                                            </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;