import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button,
    Select,
    MenuItem,
} from '@material-ui/core';
import swal from 'sweetalert';

const Tables = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }
    const [userid, setuserid] = useState(props.location.state.user_id);
    const [DeviceRID, setDeviceRID] = useState(props.location.state.id);
    const [DeviceName, setDeviceName] = useState(props.location.state.device_name);
    const [DeviceID, setDeviceID] = useState(props.location.state.device_id);
    const [DeviceLocation, setDeviceLocation] = useState(props.location.state.device_location);
    const [plantlist, setplantlist] = useState([]);
    const [plantid, setplantid] = useState(props.location.state.plant_id);
    
    const [DeviceNameError, setDeviceNameError] = useState("");
    const [DeviceIDError, setDeviceIDError] = useState("");
    const [DeviceLocationError, setDeviceLocationError] = useState("");
    const [PlantError, setPlantError] = useState("");

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
        getData();
    }, []);
    const checkValidMail = (text) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(text).toLowerCase());
    };
    const getData = async () => {
        await getAllPlantList().then((response) => {
            if (response.data.status == 400) {
            } else {
                console.log('activeplant : ' + JSON.stringify(response.data.data))
                setplantlist(response.data.data)
            }
        });
    };
    const getAllPlantList = async () => {
        const response = await axios.get(`plants/activeplant/`);
        return response;
    };
    const AddDevice = async () => {
        const device = {};

        var checkDeviceName = false;
        var checkDeviceID = false;
        var checkDeviceLocation = false;

        if (DeviceName == "") {
            setDeviceNameError("Please enter first name")
        }
        else {
            device.DeviceName = DeviceName;
            checkDeviceName = true;
            setDeviceNameError("")
        }
        if (DeviceID == "") {
            setDeviceIDError("Please enter last name")
        }
        else {
            device.DeviceID = DeviceID;
            checkDeviceID = true;
            setDeviceIDError("")
        }
        if (DeviceLocation == "") {
            setDeviceLocationError("Please enter Device Location")
        }
        else {
            device.Location = DeviceLocation;
            setDeviceLocationError("")
            checkDeviceLocation = true;
        }

        if (checkDeviceName && checkDeviceID && checkDeviceLocation) {
            await AddNewDevice(device).then((response) => {
                if (response.data.status == 400) {
                    console.log(response.data)
                    swal(response.data.message, { icon: "error" });
                } else {
                    console.log(response.data)
                    //window.location.assign("/admin/userdevice");
                    history.goBack();
                }
            });
        }
        else {

        }
    };
    const AddNewDevice = async (device) => {

        const response = await axios.post(`devices/update`, {
            div_id: DeviceRID,
            user_id: userid,
            device_name: device.DeviceName,
            device_id: device.DeviceID,
            device_location: device.Location,
            plant_id: plantid,
        });
        return response;
    };
    const handleChange = (event) => {
        setplantid(event.target.value);
        
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Edit Device</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                            }}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                            size="sm">
                                            go back
                                    </Button>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-device-name">Device Name</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-device-name"
                                                    placeholder="Device Name"
                                                    type="text"
                                                    onChange={(e) => setDeviceName(e.target.value)}
                                                    value={DeviceName}
                                                />
                                                <span className="text-danger">{DeviceNameError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-device-name">Select Plant</label>
                                                <Select
                                                    style={{ width: '100%' }}
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={plantid != "" ? plantid : ""}
                                                    displayEmpty
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">
                                                        <em>Select plant name</em>
                                                    </MenuItem>
                                                    {plantlist.map((value, index) =>
                                                        <MenuItem value={value.id}>{value.plant_name}</MenuItem>
                                                    )}
                                                </Select>
                                                <span className="text-danger">{PlantError}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-deviceid">Device Id</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-deviceid"
                                                    placeholder="Device Id"
                                                    type="DeviceLocation"
                                                    onChange={(e) => setDeviceID(e.target.value)}
                                                    value={DeviceID}
                                                />
                                                <span className="text-danger">{DeviceIDError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Device Location</label>
                                                <Input
                                                    className="form-control-alternative"

                                                    id="input-device-location"
                                                    placeholder="Device Location"
                                                    type="text"
                                                    value={DeviceLocation}
                                                    onChange={(e) => setDeviceLocation(e.target.value)}
                                                />
                                                <span className="text-danger">{DeviceLocationError}</span>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={AddDevice}
                                                size="sm">
                                                Update Device
                                    </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;
