import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button,
} from '@material-ui/core';
import swal from 'sweetalert';

const Tables = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }

    const [FirstName, setFirstName] = useState("");
    const [LastName, setLastName] = useState("");
    
    const [Email, setEmail] = useState("");
    const [Password, setPassword] = useState("");
    const [PhoneNumber, setPhoneNumber] = useState("");
    const [FirstNameError, setFirstNameError] = useState("");
    const [LastNameError, setLastNameError] = useState("");
    const [EmailError, setEmailError] = useState("");
    
    const [PasswordError, setPasswordError] = useState("");
    const [PhoneNumberError, setPhoneNumberError] = useState("");
    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
    }, []);
    const checkValidMail = (text) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(text).toLowerCase());
    };
    const ChangePhoneNumber = event => {
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPhoneNumber(event.target.value);
        }
    };
    const UpdateUser = async () => {
        const user = {};

        var checkfirstname = false;
        var checklastname = false;
        var checkemail = false;
        var checkphonenumber = false;
        var checkpassword = false;
        
        if (FirstName == "") {
            setFirstNameError("Please enter first name")
        }
        else {
            user.FirstName = FirstName;
            checkfirstname = true;
            setFirstNameError("")
        }
        if (LastName == "") {
            setLastNameError("Please enter last name")
        }
        else {
            user.LastName = LastName;
            checklastname = true;
            setLastNameError("")
        }
        if (Email == "") {
            setEmailError("Please enter email address")
        }
        else {
            if (checkValidMail(Email)) {
                user.Email = Email;
                setEmailError("")
                checkemail = true;
            }
            else {
                setEmailError("Please enter valid email address")
            }
        }
        if (PhoneNumber == "") {
            setPhoneNumberError("Please enter phone number")
        }
        else {
            user.PhoneNumber = PhoneNumber;
            checkphonenumber = true;
            setPhoneNumberError("")
        }
        if (Password == "") {
            setPasswordError("Please enter password")
        }
        else {
            user.Password = Password;
            checkpassword = true;
            setPasswordError("")
        }
        if (checkfirstname && checklastname && checkemail && checkphonenumber && checkpassword ) {
            await AddNewUser(user).then((response) => {
                if (response.data.status == 400) {
                    swal(response.data.message, { icon: "error" });
                } else {
                    window.location.assign("/admin/admins");
                }
            });
        }
        else {

        }
    };
    const AddNewUser = async (user) => {
        const response = await axios.post(`users/create`, {
            role: 1,
            first_name: user.FirstName,
            last_name: user.LastName,
            email: user.Email,
            phone: user.PhoneNumber,
            password: user.Password,            
        });
        return response;
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Add Admin</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                            }}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                            size="sm">
                                            go back
                                    </Button>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">First Name</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="First Name"
                                                    type="text"
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    value={FirstName}
                                                />
                                                <span className="text-danger">{FirstNameError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-last-name">Last Name</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-last-name"
                                                    placeholder="Last Name"
                                                    type="email"
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    value={LastName}
                                                />
                                                <span className="text-danger">{LastNameError}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Email address</label>
                                                <Input
                                                    className="form-control-alternative"

                                                    id="input-email"
                                                    placeholder="Email Address"
                                                    type="text"
                                                    value={Email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <span className="text-danger">{EmailError}</span>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-phone">Phone Number</label>
                                                <Input
                                                    className="form-control-alternative"

                                                    id="input-phone"
                                                    placeholder="Phone Number"
                                                    type="text"
                                                    value={PhoneNumber}
                                                    onChange={ChangePhoneNumber}
                                                />
                                                <span className="text-danger">{PhoneNumberError}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label className="form-control-label">Password</label>
                                                <Input
                                                    className="form-control-alternative"

                                                    id="input-email"
                                                    placeholder="Password"
                                                    type="password"
                                                    value={Password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                                <span className="text-danger">{PasswordError}</span>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={UpdateUser}
                                                size="sm">
                                                Add Admin
                                    </Button>                                            
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;
