import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../backend";
import axios from "../../axios";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";
import {
    getUserDetails,
} from "userData";
import { Autorenew } from "@material-ui/icons";

const Login = () => {
    const [user, setUser] = useState(getUserDetails());
    const [email, setEmail] = useState({
        text: "",
        error: "",
    });
    const [password, setpassword] = useState({
        text: "",
        error: "",
    });
    const [validuser, setvaliduser] = useState("");
    const checkValidMail = (text) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(text).toLowerCase());
    };
    useEffect(() => {
        console.log(user)
        if (user != "0") {
            window.location.assign("/admin/dashboard");
        }
    }, []);
    const checkAuthentication = async(login) => {
        const response = await axios.post(`users/login`, {
            email: login.email,
            password: login.password,
        });
        return response;
    };

    const checkLogin = async() => {
        setvaliduser("")
        const login = {};
        var checklogin = false;
        var checkpassword = false;
        if (email.text == "") {
            setEmail({...email, error: "Please enter email address" });
            checklogin = false;
        } else {
            if (checkValidMail(email.text)) {
                login.email = email.text;
                setEmail({...email, error: " " });
                checklogin = true;
            } else {
                checklogin = false;
                setEmail({...email, error: "Please enter valid email address" });
            }
        }
        if (password.text == "") {
            setpassword({...password, error: "Please enter password" });
            checkpassword = false;
        } else {
            login.password = password.text;
            checkpassword = true;
            setpassword({...password, error: "" });
        }
        if (checklogin && checkpassword) {
            await checkAuthentication(login).then((response) => {
                if (response.data.status == 400) {
                    setvaliduser("Invalid username or password")
                } else {
                    if (typeof window !== "undefined") {
                        localStorage.setItem("usertoken", JSON.stringify(response.data.user_token));
                        localStorage.setItem("user", JSON.stringify(response.data.data));
                        if (response.data.data.role == 1) {
                            window.location.assign("/admin/dashboard");
                        } else {
                            window.location.assign("/customer/dashboard");
                        }
                    }
                }
            });
        } else {

            console.log(checklogin)
            console.log(checkpassword)
        }
    };
    return ( <
        >
        <
        Col lg = "5"
        md = "7" >
        <
        Card className = "bg-secondary shadow border-0" >

        <
        CardBody className = "px-lg-5 py-lg-5 text-center" >
        <
        img width = { "180" }
        className = "mb-4"
        alt = "..."
        src = {
            require("../../assets/img/brand/unilink.png").default
        }
        />

        <
        Form role = "form" >
        <
        FormGroup className = "mb-3" >
        <
        InputGroup className = "input-group-alternative" >
        <
        InputGroupAddon addonType = "prepend" >
        <
        InputGroupText >
        <
        i className = "ni ni-email-83" / >
        <
        /InputGroupText> <
        /InputGroupAddon>

        <
        Input placeholder = "Email"
        type = "email"
        id = "useremail"
        autoComplete = "new-email"
        value = { email.text }
        onChange = {
            (e) => {
                setEmail({...email, text: e.target.value });
            }
        }
        /> <
        /InputGroup> <
        span className = "text-danger" > { email.error } < /span> <
        /FormGroup> <
        FormGroup >
        <
        InputGroup className = "input-group-alternative" >
        <
        InputGroupAddon addonType = "prepend" >
        <
        InputGroupText >
        <
        i className = "ni ni-lock-circle-open" / >
        <
        /InputGroupText> <
        /InputGroupAddon> <
        Input placeholder = "Password"
        type = "password"
        autoComplete = "new-password"
        value = { password.text }
        onChange = {
            (e) => {
                setpassword({...password, text: e.target.value });
            }
        }
        /> <
        /InputGroup> <
        span className = "text-danger" > { password.error } < /span> <
        span className = "text-danger" > { validuser } < /span> <
        /FormGroup>               <
        div className = "text-center" >
        <
        Button className = "my-4"
        color = "primary"
        type = "button"
        onClick = {
            () => checkLogin() } >
        Sign in
        <
        /Button> <
        /div> <
        /Form> <
        /CardBody> <
        /Card>         <
        /Col> <
        />
    );
};

export default Login;