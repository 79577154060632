import React, { useState, useEffect } from "react";
import classnames from "classnames";

import { Line, Bar } from "react-chartjs-2";
import { Card, CardHeader, CardBody, NavItem, NavLink, Nav, Progress, Table, Container, Row, Col, CardTitle, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from "reactstrap";
import {
    TablePagination,
    TableFooter,
    colors,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Select,
    MenuItem,
} from '@material-ui/core';

import axios from "../../../axios";

import { useHistory } from 'react-router';
import {
    getUserDetails,
} from "userData";

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import {Image} from "@material-ui/icons";


const useStyles = makeStyles(theme => ({

}));
const UserDeviceDetails = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(false);
    const [devicekey, setdevicekey] = useState([]);

    const [customerFirstName, setcustomerFirstName] = useState(props.location.state.company_name);
    //const [customerLastName, setcustomerLastName] = useState(props.location.state.last_name);
    const [customerId, setcustomerId] = useState(props.location.state.user_id);
    const [deviceDetailList, setdeviceDetailList] = useState([]);
    const [customerInverterList, setcustomerInverterList] = useState([]);
    //const [customerInverterValue, setcustomerInverterValue] = useState("");
    const [rowsPerPage, setrowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [realtime, setrealtime] = useState(true);
    const [DeviceName, setDeviceName] = useState("DCPower");
    const [DateRange, setDateRange] = useState([new Date(), new Date()]);
    const [chartData, setChartData] = useState({
        data: {
            labels: ["01-07-2021", "07-07-2021", "13-07-2021", "19-07-2021", "25-07-2021", "31-07-2021"],
            datasets: [
                {
                    label: String(customerFirstName),
                    data: [50, 15, 150, 7, 200, 10],
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(75,192,192,1)"
                }
            ]
        },
        options: {
            legend: {
                display: false
            },
            //tooltips: {
            //    callbacks: {
            //        label: function (tooltipItem) {
            //            return tooltipItem.yLabel;
            //        }
            //    }
            //}
        },
    });

    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
        getCustomerDevices(customerId);
        getAllDeviceKeys(customerId);
        getData(customerId, DateRange, DeviceName);

    }, []);

    const getCustomerDevices = async (customerId) => {
        await getUserDeviceDetails(customerId).then((response) => {
            if (response.data.status == 400) {
            } else {
                setcustomerInverterList(response.data.data)

                //setcustomerInverterValue(response.data.data[0].id)
            }
        });
    };

    const getAllDeviceKeys = async (customerId) => {
        await getAllDeviceKeysList(customerId).then((response) => {
            if (response.data.status == 400) {
            } else {
                setdevicekey(response.data.data);
            }
        });
    };

    const getUserDeviceDetails = async (customerId) => {
        const response = await axios.get(`devices/bycustomer?user_id=` + customerId);
        return response;
    };

    const getAllDeviceKeysList = async () => {
        const response = await axios.get(`users/getUsersWiseKeys?user_id=` + customerId);
        return response;
    };

    const getData = async (customerId, DateRange, DeviceName) => {
        setIsLoading(true);
        await getUserDeviceViseDetails(customerId, DateRange, DeviceName).then((response) => {
            if (response.data.status == 400) {
            } else {
                // console.log('response.data.data : ' + JSON.stringify(response.data.data))

                setdeviceDetailList(response.data.data)
            }
            setIsLoading(false);
        });
    };
    const getUserDeviceViseDetails = async (customerId, DateRange, DeviceName) => {
        const response = await axios.post(`users/getReportByCustomer`, {
            user_id: customerId,
            device_key: DeviceName,
            start_date: DateRange[0],
            end_date: DateRange[1],
            //div_id: customerInverterValue,
        });
        return response;
    };
    const SearchDateRangeData = (value) => {
        setDateRange(value);
        getData(customerId, value, DeviceName);
    };
    const handleChangeDevice = event => {
        setDeviceName(event.target.value);

        getData(customerId, DateRange, event.target.value);
    };
    //const handleChangeCustomerInverter = event => {
    //    setcustomerInverterValue(event.target.value)
    //    getData(customerId, DateRange, DeviceName, event.target.value);
    //};
    const handleChangePage = (event, page) => {
        setPage(page);
    };
    const handleChangeRowsPerPage = event => {
        setrowsPerPage(event.target.value);
    };


    function createLable() {
        let data = deviceDetailList
        let filTime = data.map((function ({ device_timestamp_at }) {
            return moment(device_timestamp_at).format("DD-MM-YYYY hh:MM:ss");
        }))

        return filTime
    }


    function createLableData() {
        let data = deviceDetailList
        let filData = data.map((function ({ device_value }) {
            return device_value;
        }))

        return filData
    }


    return (

        <>

            <div>
                <div className="header bg-gradient-info pb-3 pt-5 pt-md-6">
                    <Container fluid>
                        <div className="header-body">
                            {/* Card stats */}
                            <Row>
                                <Col md="12">
                                    <Card className="card-stats mb-4 mb-xl-0 mt-4">
                                        <CardBody>
                                            <Row>
                                                <Col md="8">
                                                    <Row>
                                                        <Col md="4">
                                                            <DateRangePicker className="dcsolardatepicker"
                                                                             onChange={(value) => {
                                                                                 SearchDateRangeData(value)
                                                                             }}
                                                                             value={DateRange}
                                                            />
                                                        </Col>
                                                        <Col md="4">
                                                            <span>Select Device </span>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={DeviceName}
                                                                onChange={handleChangeDevice}
                                                                displayEmpty
                                                                className={classes.selectEmpty}>
                                                                <MenuItem value="">
                                                                    <em></em>
                                                                </MenuItem>
                                                                {devicekey.length != 0 ?
                                                                    (devicekey.map((row, index) => {
                                                                        return (
                                                                            <MenuItem value={row.device_key_name}>
                                                                                <em>{row.device_key_name}</em>
                                                                            </MenuItem>
                                                                        );
                                                                    }))
                                                                    :
                                                                    <MenuItem value="">
                                                                        <em>No Record Found</em>
                                                                    </MenuItem>
                                                                }
                                                            </Select>
                                                        </Col>
                                                        {/*<Col md="4">
                                                            <span>Select Details </span>
                                                            <Select
                                                                labelId="demo-simple-select-outlined-label"
                                                                id="demo-simple-select-outlined"
                                                                value={DeviceName}
                                                                onChange={handleChangeDevice}
                                                                displayEmpty
                                                                className={classes.selectEmpty}>
                                                                <MenuItem value="">
                                                                    <em></em>
                                                                </MenuItem>
                                                                <MenuItem value="ACPower">
                                                                    <em>ACPower</em>
                                                                </MenuItem>
                                                                <MenuItem value="INVTEMP">
                                                                    <em>INVTEMP</em>
                                                                </MenuItem>
                                                                <MenuItem value="DCCurrent">
                                                                    <em>DCCurrent</em>
                                                                </MenuItem>
                                                                <MenuItem value="DCVoltage">
                                                                    <em>DCVoltage</em>
                                                                </MenuItem>
                                                                <MenuItem value="DCPower">
                                                                    <em>DCPower</em>
                                                                </MenuItem>
                                                            </Select>
                                                        </Col>*/}
                                                        { /*
                                                    <Col md="4">
                                                        <span>Select Device </span>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={customerInverterValue}
                                                            onChange={handleChangeCustomerInverter}
                                                            displayEmpty
                                                            className={classes.selectEmpty}>
                                                            <MenuItem value="">
                                                                <em></em>
                                                            </MenuItem>
                                                            {customerInverterList.length != 0 ?
                                                                (customerInverterList.map((row, index) => {
                                                                    return (
                                                                        <MenuItem value={row.id}>
                                                                            <em>{row.device_name}</em>
                                                                        </MenuItem>
                                                                    );
                                                                }))
                                                                :
                                                                <MenuItem value="">
                                                                    <em>No Record Found</em>
                                                                </MenuItem>
                                                            }
                                                        </Select>
                                                    </Col>
                                                    */}
                                                    </Row>
                                                </Col>
                                                <Col md="4">
                                                    <div className="col text-right">
                                                        <Button className="btn btn-primary" style={{ backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4" }} color="primary" onClick={() => history.goBack()} size="sm">go back</Button>
                                                    </div>
                                                </Col>
                                                { /*
                                            <Col md="3">
                                            </Col>
                                            <Col md="3">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className=""
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        Default value
                                                       <i style={{ color: 'blue', paddingLeft:15 }} className="fa fa-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem>Raw</DropdownItem>
                                                        <DropdownItem>Average</DropdownItem>
                                                        <DropdownItem>Minimum</DropdownItem>
                                                        <DropdownItem>Maximum</DropdownItem>
                                                        <DropdownItem>Sum</DropdownItem>
                                                        <DropdownItem>Count</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </Col>
                                            <Col md="3">
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        className=""
                                                        role="button"
                                                        size="sm"
                                                        color=""
                                                        onClick={(e) => e.preventDefault()}
                                                    >
                                                        Default value<i style={{ color: 'blue', paddingLeft: 15 }} className="fa fa-chevron-down" />
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                                        <DropdownItem>5 Minutes</DropdownItem>
                                                        <DropdownItem>30 Minutes</DropdownItem>
                                                        <DropdownItem>1 Hour</DropdownItem>
                                                        <DropdownItem>6 Hour</DropdownItem>
                                                        <DropdownItem>1 Day</DropdownItem>

                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </Col>
                                            <Col md="3">
                                                <i title="Refresh" onClick={() => getData()} style={{ color: 'blue', cursor: 'pointer', paddingLeft: 15 }} className="fa fa-sync-alt" />
                                                {realtime == true ?
                                                    <i title="Disable realtime" onClick={() => setrealtime(false)} style={{ color: 'blue', cursor: 'pointer', paddingLeft: 15 }} className="fa fa-pause" />
                                                    :
                                                    <i title="Enable realtime" onClick={() => setrealtime(true)} style={{ color: 'blue', cursor: 'pointer', paddingLeft: 15 }} className="fa fa-play" />
                                                    }
                                            </Col>
                                            */}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                {isLoading == false ?
                    <div >
                        <div className="bg-gradient-info pb-3 ">
                            <Container fluid>
                                <div className="header-body">
                                    {/* Card stats */}
                                    <Row>
                                        <Col md="12">
                                            <Card className="card-stats mb-4 mb-xl-0 ">
                                                <CardBody>
                                                    <Line
                                                        data={{
                                                            labels: createLable(),
                                                            datasets: [
                                                                {
                                                                    label: String(customerFirstName),
                                                                    data: createLableData(),
                                                                    fill: true,
                                                                    backgroundColor: "rgba(75,192,192,0.2)",
                                                                    borderColor: "rgba(75,192,192,1)"
                                                                }
                                                            ]
                                                        }}
                                                        options={{
                                                            legend: {
                                                                display: false
                                                            },
                                                            //tooltips: {
                                                            //    callbacks: {
                                                            //        label: function (tooltipItem) {
                                                            //            return tooltipItem.yLabel;
                                                            //        }
                                                            //    }
                                                            //}
                                                        }}
                                                        getDatasetAtEvent={(e) => console.log(e)}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>
                        <div className="bg-gradient-info pb-4 ">
                            <Container fluid>
                                <div className="header-body">
                                    {/* Card stats */}
                                    <Row>
                                        <Col md="12">
                                            <Card className="card-stats mb-4 mb-xl-0 ">
                                                <CardBody>
                                                    <Row>
                                                        <Col md="12">
                                                            <Table className="align-items-center table-flush" responsive>
                                                                <thead className="thead-light">
                                                                <tr>
                                                                    <th scope="col">Date</th>
                                                                    <th scope="col">Value</th>
                                                                    {/*<th scope="col">Context</th>
                                                                    <th scope="col">Action</th>*/}
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {deviceDetailList.length != 0 ?
                                                                    (deviceDetailList.reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                                        return (
                                                                            <tr>
                                                                                <th scope="row">{moment(row.device_timestamp_at).format("DD-MM-YYYY hh:MM:ss A")}</th>
                                                                                <td>{row.device_value}</td>
                                                                                {/*<td></td>
                                                                                <td></td>*/}
                                                                            </tr>
                                                                        );
                                                                    }))
                                                                    :
                                                                    <tr><td colSpan="5" style={{ textAlign: "center", fontSize: 20 }}>No Record Found</td></tr>
                                                                }
                                                                </tbody>
                                                                <TableFooter>
                                                                    <TablePagination
                                                                        className="customFontFamily"
                                                                        count={deviceDetailList.length}
                                                                        rowsPerPage={rowsPerPage}
                                                                        page={page}
                                                                        onChangePage={handleChangePage}
                                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                                    />
                                                                </TableFooter>
                                                            </Table>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>
                            </Container>
                        </div>
                    </div>
                    :
                    <div className="bg-gradient-info pb-3 ">
                        <Container fluid>
                            <div className="header-body">
                                {/* Card stats */}
                                <Row>
                                    <Col md="12">
                                        <Card className="card-stats mb-4 mb-xl-0 ">
                                            <CardBody>
                                                <Row>
                                                    <Col md="12">
                                                        <div className="col text-center">
                                                            <img
                                                                height={100}
                                                                width={100}
                                                                alt="..."
                                                                src={
                                                                    require("../../../assets/img/theme/loader-blu.gif")
                                                                        .default
                                                                }
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                }
            </div>

        </>

    );
};
export default UserDeviceDetails;