import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Container,
    Row,
    Input,
    Col,
    FormGroup,
} from "reactstrap";

import Header from "components/Headers/Header.js";
import {
    getUserDetails,
} from "userData";
import axios from "../../../axios";
import { useHistory } from 'react-router';
import {
    Button,
} from '@material-ui/core';
import swal from 'sweetalert';

const Tables = (props) => {
    const [user, setUser] = useState(getUserDetails());
    const history = useHistory();
    if (user == "0" || user == null || typeof user == "undefined") {
        window.location.assign("/auth/login");
    }

    const [PlantName, setPlantName] = useState("");    
    const [PlantNameError, setPlantNameError] = useState("");
    
    useEffect(() => {
        if (user == "0" || user == null || typeof user == "undefined") {
            window.location.assign("/auth/login");
        }
    }, []);
    
    const AddPlan = async () => {
        var checkPlantName = false;
        if (PlantName == "") {
            setPlantNameError("Please enter plant name")
        }
        else {
            checkPlantName = true;
            setPlantNameError("")
        }
        if (checkPlantName) {
            await AddNewPlant(user).then((response) => {
                if (response.data.status == 400) {
                    swal(response.data.message, { icon: "error" });
                } else {
                    //swal(response.data.message, { icon: "success" });
                    history.goBack();
                }
            });
        }
        else {

        }
    };
    const AddNewPlant = async (user) => {
        const response = await axios.post(`plants/create`, {
            plant_name: PlantName,
            
        });
        return response;
    };
    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <div className="col">
                                        <h3 className="mb-0">Add Plant</h3>
                                    </div>
                                    <div className="col text-right">
                                        <Button
                                            className="btn btn-primary"
                                            style={{
                                                backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                            }}
                                            color="primary"
                                            onClick={() => history.goBack()}
                                            size="sm">
                                            go back
                                    </Button>
                                    </div>
                                </Row>
                                <div className="pl-lg-4 mt-4">
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-first-name">Plant Name</label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="Plant Name"
                                                    type="text"
                                                    onChange={(e) => setPlantName(e.target.value)}
                                                    value={PlantName}
                                                />
                                                <span className="text-danger">{PlantNameError}</span>
                                            </FormGroup>
                                        </Col>
                                   
                                    </Row>
                                   
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                className="btn btn-primary"
                                                style={{
                                                    backgroundColor: "#5e72e4", color: "#fff", BorderColor: "#5e72e4"
                                                }}
                                                color="primary"
                                                onClick={AddPlan}
                                                size="sm">
                                                Add Plant
                                    </Button>
                                        </div>
                                    </Row>
                                </div>
                            </CardHeader>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default Tables;
