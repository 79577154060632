import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import { useHistory } from 'react-router';
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import CustomerLayout from "layouts/Customer";

ReactDOM.render(
  <BrowserRouter>
        <Switch>           
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />            
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/customer" render={(props) => <CustomerLayout {...props} />} />            
            <Redirect exact  from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
